#home {
    margin-top: 60px;
    padding: 0px 50px;

    ul {
        padding-left: 20px;
    }

    h2 {
        font-size: 28px;
    }

    .top {
        .description {
            margin-bottom: 10px;
            font-size: 20px;
        }

        .asterisk {
            font-size: 13px;
            padding-left: 40px;
        }
    }

    .main {
        padding: 30px;
    }

    .clinic-hours {
        >h2 {
            text-align: center;
            margin-bottom: 40px;
        }

        >div {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }

        >.asterisk {
            font-size: 15px;
            color: rgb(255, 160, 122);
        }
    }
    
    .bottom {
        >div {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 992px) { 
    #home {
        text-align: center;
        li {
            text-align: left;
        }
        .main {
            padding: 0;
        }
    }
}

@media (max-width: 768px) {
    #home { 
        // margin-top: 10px;
        padding: 0 5px;
        .top {
            .asterisk {
                padding-left: unset;
            }
        }
    }
}


  

  


  

  